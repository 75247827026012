import '../scss/app.scss';

import './bootstrap';

import { startWorker } from "./modules/workers.mjs";
import { stopWorker } from "./modules/workers.mjs";

document.addEventListener("DOMContentLoaded", function () {
    let resultOutput = document.getElementById('result');
    if (resultOutput) {
        document.getElementById('startworker').addEventListener('click', function() {
            startWorker();
        });
        document.getElementById('stopworker').addEventListener('click', function() {
            stopWorker();
        });
    }
});
