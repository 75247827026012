var w;

export function startWorker() {
    if (typeof(Worker) !== "undefined") {
        if (typeof(w) == "undefined") {
            w = new Worker(new URL("../workers/worker.js", import.meta.url));
        }
        w.onmessage = function(event) {
            document.getElementById("result").innerHTML = event.data;
        };
    } else {
        document.getElementById("result").innerHTML = "Sorry! No Web Worker support.";
    }
    return "startWorker";
}

export function stopWorker() {
    w.terminate();
    w = undefined;
    return "stopWorker";
}
